<template>
    <div class="app-container">
        <el-input
            v-model="queryInfo.query"
            clearable
            size="small"
            placeholder="输入名称搜索"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="crud.toQuery"
        />
        <span>
            <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
        </span>
        <div  class="crdc-opts">
          <span>
            <slot name="left" />
            <el-button
              class="filter-item"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="add()"
            >
              新增
            </el-button>
          </span>
          <!--右侧-->
            <slot name="right" />
          <el-button-group class="crud-opts-right">
              <el-button
                size="mini"
                icon="el-icon-refresh"
                @click="getTableData()"
              />
            </el-button-group>
        </div>
        <el-table
        :data="tableData"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          fixed
          prop="report_ID"
          label="报告ID"
          min-width="80">
        </el-table-column>
        <el-table-column
          fixed
          prop="report_TITLE"
          label="标题"
          min-width="150">
        </el-table-column>
        <el-table-column
          fixed
          prop="report_ABSTRACT"
          label="摘要"
          min-width="150">
        </el-table-column>
        <el-table-column
          fixed
          prop="reporter_NAME"
          label="作者"
          min-width="150">
        </el-table-column>
        <el-table-column
          fixed
          prop="report_DATE"
          label="报告日期"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="is_VALID_NAME"
          label="是否有效"
          min-width="50">
        </el-table-column>
        <el-table-column
          label="操作" align="center"
          min-width="180">
          <template slot-scope="scope">
            <el-button type="primary"  icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" ></el-button>
            <el-button type="danger"  icon="el-icon-delete" size="mini" @click="deleteFrom(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-card>
          <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
      <el-dialog append-to-body  title="研究报告" :visible.sync="addDialogVisible" width="35%" @close='resetForm'>
          <el-form ref="form" :rules="rules" :model="form"  label-width="80px" class="demo-ruleForm">
            <div>
            <el-form-item label="标题" prop="report_TITLE" >
              <el-col :span="11">
                <el-input v-model="form.report_TITLE"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="摘要" prop="report_ABSTRACT" >
              <el-col :span="11">
                <el-input v-model="form.report_ABSTRACT"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="作者" prop="reporter_NAME" >
              <el-col :span="11">
                <el-input v-model="form.reporter_NAME"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="报告日期" prop="report_DATE">
                <div class="block">
                <el-date-picker
                    v-model="form.report_DATE"
                    align="right"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    @change="dataChange()">
                </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item label="是否有效" prop="is_VALID">
              <el-select v-model="form.is_VALID"  placeholder="请选择">
                <el-option
                  v-for="item in isOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  label="展示图片" >
              <el-upload
                class="upload-demo"
                ref="upload"
                action="#"
                :on-remove="handleRemove"
                :on-change="imgChange"
                :limit="1"
                :file-list="fileList"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
              </el-upload>
            </el-form-item>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="resetForm()" >取消</el-button>
            <el-button v-if="isAdd == true" type="primary" @click="submitFrom('form')">确认</el-button>
            <el-button  v-if="isAdd == false" type="primary" @click="updateFrom('form')">修改</el-button>
          </div>
      </el-dialog>
      </el-card>
    </div>
</template>
<script>
import axios from 'axios'
var mytoken = window.sessionStorage.getItem('token')
export default {
  name: 'ResearchReport',
  created () { //  初始化
    this.getTableData()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/researchReport', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    companyCurrentChange (val) {
    },
    add () {
      this.addDialogVisible = true
      this.isAdd = true
    },
    handleEdit (index, row) {
      this.addDialogVisible = true
      this.form.report_TITLE = row.report_TITLE
      this.form.report_ABSTRACT = row.report_ABSTRACT
      this.form.reporter_NAME = row.reporter_NAME + ''
      this.form.is_VALID = row.is_VALID + ''
      this.form.report_DATE = row.report_DATE
      this.form.report_ID = row.report_ID + ''
      this.isAdd = false
    },
    resetForm () {
      this.addDialogVisible = false
      this.form.report_TITLE = ''
      this.form.report_ABSTRACT = ''
      this.form.reporter_NAME = ''
      this.form.is_VALID = ''
      this.form.report_DATE = ''
      this.form.report_ID = ''
    },
    async submitFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.fileList === undefined || this.form.fileList.length < 0) {
            this.$message({
              message: '文件不能为空',
              type: 'warning'
            })
          } else {
            isPass = true
          }
        }
      })
      if (isPass) {
        const url = axios.defaults.baseURL
        const formData = new FormData()
        let path = ''
        // 不含图片时和含图片请求分开
        if (this.form.fileList && this.form.fileList.length > 0) {
          path = '/upload/researchReport'
          formData.append('file', this.form.fileList[0].raw)
        } else {
          path = '/insert/researchReport'
        }
        formData.append('report_TITLE', this.form.report_TITLE)
        formData.append('report_ABSTRACT', this.form.report_ABSTRACT)
        formData.append('reporter_NAME', this.form.reporter_NAME)
        formData.append('is_VALID', this.form.is_VALID)
        formData.append('report_DATE', this.form.report_DATE)
        formData.append('report_YEAR', this.form.report_DATE.split('-')[0])
        axios.post(url + path, formData, { Authorization: mytoken, 'Content-Type': 'multipart/form-data' }
        ).then(res => {
          this.addDialogVisible = false
          this.getTableData()
        })
      }
    },
    async updateFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.fileList === undefined || this.form.fileList.length < 0) {
            this.$message({
              message: '文件不能为空',
              type: 'warning'
            })
          } else {
            isPass = true
          }
        }
      })
      if (isPass) {
        const url = axios.defaults.baseURL
        const formData = new FormData()
        let path = ''
        // 不含图片时和含图片请求分开
        if (this.form.fileList && this.form.fileList.length > 0) {
          path = '/upload/update/researchReport'
          formData.append('file', this.form.fileList[0].raw)
        } else {
          path = '/update/researchReport'
        }
        formData.append('report_ID', this.form.report_ID)
        formData.append('report_TITLE', this.form.report_TITLE)
        formData.append('report_ABSTRACT', this.form.report_ABSTRACT)
        formData.append('reporter_NAME', this.form.reporter_NAME)
        formData.append('is_VALID', this.form.is_VALID)
        formData.append('report_DATE', this.form.report_DATE)
        formData.append('report_YEAR', this.form.report_DATE.split('-')[0])
        axios.post(url + path, formData, { Authorization: mytoken, 'Content-Type': 'multipart/form-data' }
        ).then(res => {
          this.addDialogVisible = false
          this.getTableData()
        })
      }
    },
    async deleteFrom (index, row) {
      await this.$http.post('delete/researchReport', row)
      this.getTableData()
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    handleRemove (file) {
    },
    imgChange (file, fileList) {
      // this.form.name = file.name
      this.form.fileList = fileList
    },
    dataChange () {
      this.form.report_YEAR = this.form.report_DATE.split('-')[0]
    }
  },
  data () {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      totle: 0,
      form: {
        report_TITLE: '',
        report_ABSTRACT: '',
        reporter_NAME: '',
        is_VALID: '',
        report_DATE: '',
        report_ID: '',
        report_YEAR: '',
        query: ''
      },
      isOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      tableData: [],
      addDialogVisible: false,
      dialogVisible: false,
      isAdd: '',
      fileList: [],
      rules: {
        report_TITLE: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        report_ABSTRACT: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        reporter_NAME: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        report_DATE: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        is_VALID: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      }
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
</style>
